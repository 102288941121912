import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      file(relativePath: { eq: "hero.jpg" }) {
        name
        childImageSharp {
          fluid(
            maxWidth: 1920
            duotone: { shadow: "#000000", highlight: "#000000", opacity: 70 }
            quality: 90
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ...LogoImageFixed
    }
  `);

  return (
    <section
      className={
        "container mx-auto px-2 relative flex flex-wrap text-center antialiased text-white leading-none items-center py-24 md:py-32"
      }
    >
      <div
        className={"full-bleed absolute top-0 left-0 w-full h-full"}
        css={css`
          z-index: -1;
        `}
      >
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt={data.file.name}
          imgStyle={{ objectPosition: "left center" }}
          className={"w-full h-full"}
          fadeIn={false}
          loading={"eager"}
        />
      </div>
      <div className={"hidden sm:block w-full"}>
        <img
          src={data.logoImageFixed.childImageSharp.fixed.src}
          alt={data.logoImageFixed.name}
          className={"max-w-full mx-auto"}
        />
      </div>
      <h1
        className={
          "mt-4 w-full text-3xl sm:text-5xl md:text-6xl font-accent max-w-3xl mx-auto"
        }
      >
        Electrical contractor services for everything under the sun
      </h1>
      <ul
        className={
          "w-full mt-4 flex justify-center text-center text-sm sm:text-xl md:text-2xl"
        }
      >
        <li className={"px-2 md:px-4"}>Industrial</li>
        <li className={"px-2 md:px-4 border-l-2 border-yellow-400"}>
          Commercial
        </li>
        <li className={"px-2 md:px-4 border-l-2 border-yellow-400"}>
          Residential
        </li>
      </ul>
      <div
        className={
          "mt-6 md:mt-10 w-full flex flex-col justify-center items-center"
        }
      >
        <span className="mt-2 inline-flex rounded-md shadow-sm flex justify-end">
          <AnchorLink
            to={"/#estimate"}
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-primary-alt transition ease-in-out duration-150"
          >
            Request an estimate
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                fillRule="evenodd"
              />
            </svg>
          </AnchorLink>
        </span>
        <span className="mt-2 inline-flex rounded-md shadow-sm flex justify-end">
          <AnchorLink
            to={"/#emergency"}
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-transparent hover:text-primary hover:bg-white focus:outline-none focus:border-white focus:shadow-outline-white active:bg-white transition ease-in-out duration-150"
          >
            Have an emergency?
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.929 17.071c3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142zm12.728-1.414A8 8 0 104.343 4.343a8 8 0 0011.314 11.314zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                fillRule="evenodd"
              />
            </svg>
          </AnchorLink>
        </span>
      </div>
    </section>
  );
};

export default Hero;
