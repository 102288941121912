import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const Estimate = () => {
  const logoImage: {
    logoImage: {
      name: string;
      childImageSharp: { fixed: { src: any } };
    };
  } = useStaticQuery(graphql`
    query EstimateLogoQuery {
      ...LogoImageFixed
    }
  `).logoImageFixed;

  return (
    <section
      className={
        "flex flex-wrap px-4 md:px-8 py-12 md:py-24 text-white text-center bg-gradient"
      }
      id={"estimate"}
    >
      <div className={"container mx-auto"}>
        <h2 className={"text-4xl md:text-5xl font-bold leading-tight"}>
          Zero Hassle, Zero Cost Estimates
        </h2>
        <p className={"mt-2 max-w-xl mx-auto"}>
          We make it easy to get a free estimate. With years of experience,
          we’re confident we’ll be able to help with any of your electrical
          needs.
        </p>
        <span className="mt-6 md:mt-8 inline-flex rounded-md flex justify-center">
          <a
            href="tel:+1-920-210-5266"
            className="inline-flex shadow-lg items-center px-2 md:px-4 py-2 border border-transparent text-3xl md:text-4xl font-medium rounded text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:border-green-700 focus:shadow-outline-green-700 active:bg-primary-green-700 transition ease-in-out duration-150"
          >
            (920)-210-5266
          </a>
        </span>
        <p className={"text-lg md:text-xl mt-2 md:mt-4"}>
          Available 8-5 Mon-Fri
        </p>
        <div className={"max-w-xl mx-auto space-y-2"}>
          <p
            className={
              "text-2xl md:text-4xl font-bold text-yellow-300 mt-8 md:mt-12"
            }
            id={"emergency"}
          >
            24/7 Emergency Service:
          </p>
          <p>
            For emergency service, please call our main line at{" "}
            <a
              href="tel:+1-920-210-5266"
              className="border-b border-yellow-300"
            >
              (920)-210-5266
            </a>
          </p>
          <p>
            If we are unable to answer the phone immediately, please leave a
            message so that we may return your call as soon as possible.
          </p>
        </div>
        <img
          src={logoImage.childImageSharp.fixed.src}
          alt={logoImage.name}
          className={"mt-2 md:mt-4 max-w-full mx-auto"}
        />
      </div>
    </section>
  );
};

export default Estimate;
