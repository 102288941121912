import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

const TestimonialAlt = () => {
  const {
    shawnWorkingImage,
    logoSun,
  }: {
    shawnWorkingImage: ImageBoxProps["image"];
    logoSun: ImageBoxProps["image"];
  } = useStaticQuery(graphql`
    query TestimonialAltQuery {
      shawnWorkingImage: file(
        relativePath: { eq: "testimonials/shawn-working.jpeg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      logoSun: file(relativePath: { eq: "logo-sun.png" }) {
        name
        childImageSharp {
          fixed(width: 102) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className={"flex flex-wrap mt-16"}>
      <TextBox
        title={`Jane Brown`}
        text={[
          `“Sunsation is always on time, reliable and doesn’t ever cut corners.”`,
        ]}
        subtitle={[`Waupun, WI`]}
        smallImage={logoSun}
      />
      <ImageBox image={shawnWorkingImage} />
    </section>
  );
};

type TextBoxProps = {
  title: string;
  subtitle: string[];
  idx?: number;
  text: string[];
  smallImage: {
    name: string;
    childImageSharp: GatsbyImage;
  };
};

const TextBox = ({ text, title, subtitle, smallImage }: TextBoxProps) => {
  return (
    <div
      className={
        "w-full md:w-1/2 p-2 pr-2 md:pr-4 py-4 md:py-6 flex flex-col justify-center text-center"
      }
    >
      <div className={"space-y-4 max-w-md mx-auto"}>
        <Img {...smallImage.childImageSharp} alt={smallImage.name} />
        {text.map((txt, i) => (
          <p className={"text-2xl md:text-3xl"} key={i}>
            {txt}
          </p>
        ))}

        <div>
          <p className={"text-3xl md:text-4xl leading-tight"}>{title}</p>
          {subtitle.map((txt, i) => (
            <p
              className={`${i === 0 ? "text-lg" : "text-sm"} leading-tight`}
              key={i}
            >
              {txt}
            </p>
          ))}
        </div>
        <span className="inline-flex rounded-md flex justify-center">
          <AnchorLink
            to={"/#estimate"}
            className="inline-flex shadow-sm items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-primary-alt transition ease-in-out duration-150"
          >
            Request an estimate
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                fillRule="evenodd"
              />
            </svg>
          </AnchorLink>
        </span>
      </div>
    </div>
  );
};

type ImageBoxProps = {
  image: {
    name: string;
    childImageSharp: GatsbyImage;
  };
};
const ImageBox = ({ image }: ImageBoxProps) => {
  return (
    <div className={"w-full md:w-1/2 flex items-center mt-8 md:mt-0"}>
      <Img className={"w-full"} {...image.childImageSharp} alt={image.name} />
    </div>
  );
};

export default TestimonialAlt;
