import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

const About = () => {
  const data: {
    [key: string]: ImageBoxProps["image"];
  } = useStaticQuery(graphql`
    query AboutImageQuery {
      russAndShawnImage: file(
        relativePath: { eq: "about/russ-shawn-welcome-swan-city.jpeg" }
      ) {
        name
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      cityBdLogo: file(relativePath: { eq: "about/city-of-bd.png" }) {
        name
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      focusOnEnergyLogo: file(
        relativePath: { eq: "about/focus-on-energy.png" }
      ) {
        name
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className={"flex flex-wrap container mx-auto mt-16"}>
      <TextBox
        title={`About Us`}
        text={[
          `Sunsation Electric is veteran owned and has been helping home owners and businesses in South Eastern Wisconsin for over 15 years.`,
          `We give you the peace of mind that comes from knowing the work will be done right, on time, and at a fair price.`,
        ]}
      />
      <ImageBox image={data.russAndShawnImage} />
      <Logos
        logos={[
          {
            image: data.cityBdLogo,
            title: "Active Chamber of Commerce Member",
          },
          { image: data.focusOnEnergyLogo, title: "Eco-Friendly Solutions" },
        ]}
      />
    </section>
  );
};

const TextBox = ({ title, idx = 0, text }) => {
  return (
    <div
      className={`${
        idx % 2 === 0
          ? "order-first pr-4"
          : "order-first md:order-last md:text-right pr-2 md:pl-4"
      } w-full md:w-1/2 p-2 md:px-8 flex flex-col justify-center`}
    >
      <div className={"space-y-4"}>
        <h2 className={"text-3xl md:text-4xl"}>{title}</h2>
        {text.map((txt, i) => (
          <p key={i}>{txt}</p>
        ))}
        <span className="inline-flex rounded-md shadow-sm flex justify-end">
          <AnchorLink
            to={"/#estimate"}
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-primary-alt transition ease-in-out duration-150"
          >
            Request an estimate
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                fillRule="evenodd"
              />
            </svg>
          </AnchorLink>
        </span>
      </div>
    </div>
  );
};

type ImageBoxProps = {
  image: {
    name: string;
    childImageSharp: GatsbyImage;
  };
};
const ImageBox = ({ image }: ImageBoxProps) => {
  return (
    <div className={"w-full md:w-1/2 flex flex-wrap items-center mt-8 md:mt-0"}>
      <Img className={"w-full"} {...image.childImageSharp} alt={image.name} />
      <caption className={"text-xs max-w-sm mx-auto mt-1"}>
        Russ & Shawn after rewiring the "Welcome" sign at Swan City Park in
        Beaver Dam
      </caption>
    </div>
  );
};

const Logos = ({ logos }) => {
  return (
    <div
      className={"w-full flex flex-wrap mt-10 md:mt-16 space-y-10 md:space-y-0"}
    >
      {logos.map((logo, i) => (
        <div
          className={
            "w-full md:w-1/2 flex flex-wrap items-center justify-center"
          }
          key={i}
        >
          <div className={"w-full"}>
            <img
              src={logo.image.childImageSharp.fixed.src}
              alt={logo.image.name}
              style={{ maxHeight: "10rem", margin: "0 auto" }}
            />
          </div>
          <caption className={"mt-2 w-full"}>{logo.title}</caption>
        </div>
      ))}
    </div>
  );
};

export default About;
