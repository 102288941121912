import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/sections/Hero";
import Service from "../components/sections/Service";
import { graphql } from "gatsby";
import Testimonial from "../components/sections/Testimonial";
import About from "../components/sections/About";
import TestimonialAlt from "../components/sections/TestimonialAlt";
import Contact from "../components/sections/Contact";
import Estimate from "../components/sections/Estimate";
import Newsletter from "../components/sections/Newsletter";

const IndexPage = ({ data, location }) => {
  const {
    residentialImage,
    gemstoneLighting,
    commercialImage,
    industrialImage,
    billJeffImage,
    beforeImage,
    afterImage,
  } = data;

  const services = [
    {
      heading: "Residential Services",
      text: [
        "Repair, consulting, renovations and everything in between.",
        "Let us help you get your wiring check. You can rest easy knowing the job was done right and with quality parts. We’ll help you keep your home safe and energy costs down.",
      ],
      listItems: [
        "New Home Wiring & Home Re-wiring",
        "New Services & Service Upgrades",
        "Powering Up New Additions",
        "Service & Repair Calls",
      ],
      image: residentialImage,
    },
    {
      heading: "Commercial Services",
      text: [
        "Storefronts, shops, malls, office buildings, and any other commercial or professional space.",
      ],
      listItems: ["New Construction", "Remodels", "Service & Repair Calls"],
      image: commercialImage,
    },
    {
      heading: "Industrial Services",
      text: [
        "Warehouse space, factories, manufacturing, waste water treatment plants and other industrial buildings.",
      ],
      listItems: ["New Construction", "Remodels", "Service & Repair Calls"],
      image: industrialImage,
    },
    {
      heading: "Gemstone Lighting",
      text: [
        "Permanent, Customizable LED lights.",
        "We are the only authorized dealer of gemstone lighting in Wisconsin!",
      ],
      listItems: [
        "Architectural Lighting",
        "Seasonal & Holidays",
        "Easy to Install",
      ],
      additionalCta: {
        href: "https://www.gemstonelights.com",
        label: "Learn more about Gemstone Lighting",
        target: "_blank",
      },
      image: gemstoneLighting,
    },
  ];

  return (
    <Layout>
      <Seo title={"Home"} pathname={location.pathname} />
      <Hero />
      {services.map((service, i) => (
        <Service {...service} key={i} idx={i} />
      ))}
      <Testimonial
        title={"Jeff Davidson & Bill Schwartz"}
        text={[
          `“We’ve been working with Sunsation for more than 14 years and they have never steered us in the wrong direction. They are thoughtful and always willing to offer options for difficult installations. Russ and Shawn are both awesome to work with and I’m happy to recommend them to anyone.”`,
        ]}
        subtitle={[
          `Founders of Inter-Quest, Corp`,
          `Business IT & Audio/Video Provider in Beaver Dam, WI`,
        ]}
        images={[billJeffImage]}
      />
      <About />
      <Newsletter />
      <Testimonial
        title={"John Smith"}
        text={[
          `
“I have an older home where everything seems to be outdated and breaking. Sunsation came in and knew exactly what to do. They were so pleasant to work with and I know that everything was done the right way.”`,
        ]}
        subtitle={[`Home Owner`, `Columbus, WI`]}
        images={[beforeImage, afterImage]}
      />
      <TestimonialAlt />
      <Estimate />
      <Contact />
    </Layout>
  );
};

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    residentialImage: file(relativePath: { eq: "services/residential.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gemstoneLighting: file(
      relativePath: { eq: "services/gemstone-lighting.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    commercialImage: file(relativePath: { eq: "services/commercial.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    industrialImage: file(relativePath: { eq: "services/industrial.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    billJeffImage: file(
      relativePath: { eq: "testimonials/bill-and-jeff.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    beforeImage: file(
      relativePath: { eq: "testimonials/panel-before-w-text.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    afterImage: file(
      relativePath: { eq: "testimonials/panel-after-w-text.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
