import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";

const Newsletter = () => {
  const { lightBulbImage } = useStaticQuery(graphql`
    query NewsletterQuery {
      lightBulbImage: file(relativePath: { eq: "light-bulb-energy.jpg" }) {
        name
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <section
      className={"py-24 sm:py-32 text-center px-2 mt-16"}
      css={css`
        background-image: url('${lightBulbImage.childImageSharp.fixed.src}');
        background-position: top right;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 150px;
        @media (min-width: 640px) {
          background-size: 200px;
        }
        @media (min-width: 950px) {
          background-size: 300px;
        }
    `}
    >
      <div className={"max-w-2xl mx-auto space-y-4"}>
        <h2 className={"text-2xl md:text-3xl font-bold uppercase"}>
          Go <span className={"text-green-600"}>Green</span>
        </h2>
        <p>
          Enter your email and we’ll periodically provide you with great tips as
          well as deals on products and services that can help cut your energy
          bill.
        </p>
        <span className="inline-flex rounded-md shadow-sm flex justify-end">
          <a
            href={
              "https://email.wemaketechsimple.com/h/r/6DFB8A2401BEFFB12540EF23F30FEDED"
            }
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-600 focus:shadow-outline-green-600 active:bg-green-500 transition ease-in-out duration-150"
          >
            Sign Up
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                fillRule="evenodd"
              />
            </svg>
          </a>
        </span>
      </div>
    </section>
  );
};

export default Newsletter;
