import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { css } from "@emotion/core";

import Img from "gatsby-image";
import GatsbyImage from "gatsby-image";
import CheckmarkSvg from "../../images/checkmark-icon.svg";

type ServiceProps = {
  heading: string;
  text: string[];
  listItems: string[];
  image: {
    name: string;
    childImageSharp: GatsbyImage;
  };
  additionalCta: {
    href: string;
    label: string;
  };
  idx?: number;
};
const Service = ({
  heading,
  text,
  listItems,
  image,
  additionalCta,
  idx = 0,
}: ServiceProps) => {
  return (
    <section className={"flex flex-wrap mt-16"}>
      <TextBox
        idx={idx}
        heading={heading}
        text={text}
        listItems={listItems}
        additionalCta={additionalCta}
      />
      <ImageBox image={image} />
    </section>
  );
};

const TextBox = ({ heading, idx = 0, text, listItems, additionalCta }) => {
  return (
    <div
      className={`${
        idx % 2 === 0
          ? "order-first pr-4"
          : "order-first md:order-last md:text-right pr-2 md:pl-4"
      } w-full md:w-1/2 p-2 md:px-8 flex flex-col justify-center`}
    >
      <div
        className={`space-y-4 text-lg lg:text-xl max-w-xl ${
          idx % 2 === 0 ? "md:mr-auto" : "md:ml-auto"
        }`}
      >
        <h2 className={"text-3xl md:text-4xl lg:text-5xl"}>{heading}</h2>
        {text.map((txt, i) => (
          <p key={i}>{txt}</p>
        ))}
        <ul
          css={css`
        padding-left: 1.5em;
        li span {
          position: relative;
        }
        li span:before {
          position: absolute;
          display: block;
          content: "";
          left: -1em;
          width: .75em;
          height: .75em;
          top: 50%;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-image: url("${CheckmarkSvg}");
          background-size: cover;
          background-position: center;
        }
        `}
        >
          {listItems.map((item, i) => (
            <li key={i}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
        {additionalCta && (
          <div className="w-full pb-4">
            <a className="text-primary" {...additionalCta}>
              {additionalCta.label}
            </a>
          </div>
        )}
        <span className="inline-flex rounded-md shadow-sm flex justify-end">
          <AnchorLink
            to={"/#estimate"}
            className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-primary-alt transition ease-in-out duration-150"
          >
            Request an estimate
            <svg
              fill="currentColor"
              className="ml-1 md:ml-3 -mr-1 h-4 w-4"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                fillRule="evenodd"
              />
            </svg>
          </AnchorLink>
        </span>
      </div>
    </div>
  );
};

const ImageBox = ({ image }) => {
  return (
    <div className={"w-full md:w-1/2 mt-8 md:mt-0"}>
      <Img
        className={"h-full w-full"}
        {...image.childImageSharp}
        alt={image.name}
      />
    </div>
  );
};

export default Service;
