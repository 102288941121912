import * as React from "react";

import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";

type TestimonialProps = {
  title: string;
  text: string[];
  subtitle: string[];
  images: Array<{
    name: string;
    childImageSharp: { fixed: { src: any } };
  }>;
};
const Testimonial = ({ title, text, subtitle, images }: TestimonialProps) => {
  if (images.length > 2)
    console.error("Testimonial component only accepts up to 2 images.");
  const { childImageSharp, name } = useStaticQuery(
    graphql`
      query LightBulbGraphic {
        file(relativePath: { eq: "testimonials/lightbulb-quote.png" }) {
          name
          childImageSharp {
            fixed(height: 250) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  ).file;

  return (
    <>
      <Divider top className={"mt-16"} />
      <section className={"relative flex flex-wrap bg-yellow-200 text-black"}>
        <GraphicContainer name={name} childImageSharp={childImageSharp} />
        <TextBox title={title} text={text} subtitle={subtitle} />
        <ImageBox images={images} />
      </section>
      <Divider bottom />
    </>
  );
};

const TextBox = ({ text, title, subtitle }) => {
  return (
    <div
      className={
        "w-full md:w-2/5 p-2 pr-2 md:pr-4 py-4 md:py-6 flex flex-col justify-center text-gray-900"
      }
    >
      <div className={"space-y-4 max-w-md mx-auto"}>
        {text.map((txt, i) => (
          <p className={"text-xl md:text-2xl leading-tight"} key={i}>
            {txt}
          </p>
        ))}

        <div>
          <p className={"text-3xl md:text-4xl leading-tight"}>{title}</p>
          {subtitle.map((txt, i) => (
            <p
              className={`${i === 0 ? "text-lg" : "text-sm"} leading-tight`}
              key={i}
            >
              {txt}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const ImageBox = ({ images }) => {
  return (
    <div className={"w-full md:w-2/5 flex flex-wrap items-center mt-4 md:mt-0"}>
      {images.length > 1 ? (
        images.map((image, i) => (
          <Img
            className={"w-full xl:h-full xl:w-1/2"}
            {...image.childImageSharp}
            alt={image.name}
            key={i}
          />
        ))
      ) : (
        <Img
          className={"w-full h-full"}
          {...images[0].childImageSharp}
          alt={images[0].name}
        />
      )}
    </div>
  );
};

const GraphicContainer = ({ childImageSharp, name }) => {
  return (
    <div className={"w-full md:w-1/5 pl-6 lg:pl-8 pr-2"}>
      <img
        src={childImageSharp.fixed.src}
        alt={name}
        className={"h-32 md:h-48"}
      />
    </div>
  );
};

type DividerProps = {
  className?: string;
  top?: boolean;
  bottom?: boolean;
};
const Divider = ({ className, top = false, bottom = false }: DividerProps) => {
  return (
    <div
      className={`w-full ${className}`}
      css={css`
        transform: scaleY(${top ? "-1" : "1"});
        line-height: 0;
        ${top ? "margin-bottom: -1px;" : "margin-top: -1px;"}
      `}
    >
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        className={"text-yellow-200"}
        fill={"currentColor"}
      >
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" />
        {/*<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" />*/}
      </svg>
    </div>
  );
};
const DividerBottom = ({ className }) => {
  return (
    <div
      className={`w-full ${className}`}
      style={{ lineHeight: "0" }}
      css={css`
        line-height: 0;
      `}
    >
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        className={"text-yellow-200"}
        fill={"currentColor"}
      >
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" />
      </svg>
    </div>
  );
};

const DividerBottomAbsolute = () => {
  return (
    <div
      className="absolute bottom-0 left-0 w-full overflow-hidden"
      style={{ lineHeight: "0", transform: "translateY(100%)" }}
    >
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
        className={"text-yellow-200"}
        fill={"currentColor"}
      >
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" />
      </svg>
    </div>
  );
};

export default Testimonial;
